export const InitialState = {
  hamburgerMenu: false,
  headerHeight: 0,
  newsEvent: null
};

export const actionTypes = {
  SET_HAMBURGER_MENU: "SET_HAMBURGER_MENU",
  SET_HEADER_HEIGHT: "SET_HEADER_HEIGHT",
  SET_NEWS_EVENT: "SET_NEWS_EVENT",
};

export const Reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_HAMBURGER_MENU:
      if (document) {
        //maybe some action
      }
      return {
        ...state,
        hamburgerMenu: action.payload,
      };
    case actionTypes.SET_HEADER_HEIGHT:
      return {
        ...state,
        headerHeight: action.payload,
      };
    case actionTypes.SET_NEWS_EVENT:
      return {
        ...state,
        newsEvent: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
